import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"
import classNames from "classnames"
import View from "components/View"
import Checklist from "components/Page/Checklist"
import device from "utils/media"

const SectionWrapper = styled.div`
  padding: ${props => (props.zeroPadding ? "0" : "60px 0")};
  @media ${device.lg} {
    padding: ${props => (props.zeroPadding ? "0" : "70px 0")};
  }
  background-color: ${props => props.bgColour};
  color: ${props => props.textColour};
`

const MainWrapper = styled.div`
  position: relative;

  p.lead {
    color: black;
  }

  .intro-text {
    font-size: ${props => props.theme.text.base};
    padding: 0 0 10px 0;
    @media ${device.md} {
      padding-top: 10px;
    }
    @media ${device.lg} {
      font-size: ${props => props.theme.text.base_2};
    }
  }

  .featured_image {
    @media ${device.md} {
      width: 94%;
    }
    @media ${device.lg} {
      margin: ${props =>
        props.pageName === "data-and-insights" ? "0 auto" : "0"};
    }
  }

  .has_intro {
    // margin: 0 auto 40px auto;
    .featured_image {
      @media ${device.sm} {
        // width: 90%;
      }
      @media ${device.md} {
        width: 100%;
        margin: 0 auto;
      }
      @media ${device.lg} {
        width: 85%;
      }
    }
  }

  .text_column {
    padding-top: ${props =>
      props.pageName === "data-and-insights" ? "30px" : "10px"};
    @media ${device.lg} {
      padding-top: 10px;
    }
    h2 {
      margin-bottom: 40px;
      margin-top: 20px;
      @media ${device.lg} {
        margin-top: 0;
      }
    }
    .simple_text__data-and-insights {
      font-size: ${props => props.theme.text.base};
      @media ${device.xl} {
        font-size: ${props => props.theme.text.md};
        padding: 0;
      }
      @media ${device.xxl} {
        font-size: ${props => props.theme.text.md_2};
        padding: 0;
      }
    }

    p > a {
      background-color: ${props => props.theme.color.light_teal};
      border-radius: 3px;
      padding: 0.1em 0.3em;
      color: white;
      &:hover {
        background-color: ${props => props.theme.color.teal};
      }
    }
  }

  .no-max {
    .featured_image {
      margin: 0;
      width: 100%;
      @media ${device.md} {
        width: ${props =>
          props.pageName === "data-and-insights" ? "100%" : "90%"};
      }
    }
    .text_column {
      padding: 30px;
      @media ${device.xl} {
        padding: 60px;
      }
      @media ${device.xxl} {
        padding: 80px;
      }
    }
  }

  .btn-learn_more {
    color: #000000;
    font-style: italic;
  }
`

const SectionTitle = styled.h2`
  margin: 0 0 30px 0;
  color: ${props => props.textColor};
`

const AdditionalText = styled.div`
  padding-top: 10px;
  .note-info {
    display: flex;
    flex-direction: row;
    font-size: ${props => props.theme.text.base};
    @media ${device.lg} {
      font-size: ${props => props.theme.text.base_2};
    }    
    > strong:first-child {
      //display: flex;
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        border: 2px dashed ${({theme}) => theme.color.teal};
        position: absolute;
        top: 28px;
        left: 0;
      }
    }
    .note-content {
      display: flex;
      padding-left: 8px;
    }
  }
`

const ImageTextSplit = ({ fields, pageName }) => {
  const containerClasses =
    fields &&
    classNames("container", {
      "no-max no-padding": fields.remove_horizontal_margin,
      "x-large":
        !fields.intro_enabled &&
        !fields.remove_horizontal_margin &&
        pageName !== "data-and-insights",
      "x-large has_intro":
        fields.intro_enabled && !fields.remove_horizontal_margin,
      has_intro: fields.intro_enabled && fields.remove_horizontal_margin,
    })

  const rowClasses =
    fields &&
    classNames("row align-items-center-lg", {
      "no-gutter": fields.remove_horizontal_margin,
    })

  const imageColumnClasses =
    fields &&
    classNames("col", {
      "lg-5":
        fields.remove_horizontal_margin && pageName !== "data-and-insights",
      "lg-6":
        !fields.remove_horizontal_margin || pageName === "data-and-insights",
      "last-xs":
        fields.image_alignment === "right" && !fields.reverse_order_mobile,
      "first-xs last-lg":
        fields.image_alignment === "right" && fields.reverse_order_mobile,
      "last-xs first-lg":
        fields.image_alignment === "left" && fields.reverse_order_mobile,
    })

  const textColumnClasses =
    fields &&
    classNames("col", {
      "lg-7":
        fields.remove_horizontal_margin && pageName !== "data-and-insights",
      "lg-6":
        !fields.remove_horizontal_margin || pageName === "data-and-insights",
      "first-xs":
        fields.image_alignment === "right" && !fields.reverse_order_mobile,
      "last-xs first-lg":
        fields.image_alignment === "right" && fields.reverse_order_mobile,
      "first-xs last-lg":
        fields.image_alignment === "left" && fields.reverse_order_mobile,
    })

  return (
    <SectionWrapper
      as={View}
      bgColour={fields.background_colour || "transparent"}
      textColour={fields.text_colour || "#000000"}
      zeroPadding={
        fields.remove_horizontal_margin && pageName === "data-and-insights"
      }
    >
      <MainWrapper pageName={pageName}>
        {fields.intro_enabled && (
          <div className="container x-large">
            <div className="row">
              <div className="col md-10">
                {fields.intro_title && (
                  <SectionTitle
                    dangerouslySetInnerHTML={{ __html: fields.intro_title }}
                    textColor={fields.text_colour || `#000000`}
                  />
                )}
                <div className="intro-text">
                  {fields.intro_text && (
                    <div
                      dangerouslySetInnerHTML={{ __html: fields.intro_text }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={containerClasses}>
          <div className={rowClasses}>
            <div className={imageColumnClasses}>
              <div className="featured_image">
                {fields.image && (
                  <img
                    src={fields.image.url}
                    alt={fields.image.alt}
                    title={fields.image.alt}
                  />
                )}
              </div>
            </div>
            <div className={textColumnClasses}>
              <div className="text_column">
                {fields.title && (
                  <h2 dangerouslySetInnerHTML={{ __html: fields.title }} />
                )}
                {fields.content_type === "simple_text" && fields.text && (
                  <div
                    className={`simple_text simple_text__${pageName}`}
                    dangerouslySetInnerHTML={{ __html: fields.text }}
                  />
                )}
                {fields.content_type !== "simple_text" &&
                  fields.items.length > 0 && (
                    <Checklist 
                      items={fields.items} 
                      listType={fields.content_type} 
                      startText={fields.checklist_start_text}
                      endText={fields.checklist_end_text}
                      itemBgColour={fields.item_bg_colour}
                      itemSize="large"
                    />
                  )}
              </div>
            </div>
          </div>
          {fields.additional_text && (
            <div className="row">
              <AdditionalText
                className="col md-10"
                dangerouslySetInnerHTML={{ __html: fields.additional_text }}
              />
            </div>
          )}
        </div>
      </MainWrapper>
    </SectionWrapper>
  )
}

export default ImageTextSplit
