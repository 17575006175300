import React from "react"
import styled from "styled-components"

import LocationIcon from "../../../content/assets/icon-location__white.svg"
import MultiDeviceIcon from "../../../content/assets/icon-multi-device__white.svg"
import LockedIcon from "../../../content/assets/icon-locked__white.svg"
import UnlockedIcon from "../../../content/assets/icon-unlocked__white.svg"
import CursorIcon from "../../../content/assets/icon-cursor__white.svg"
import TickIcon from "../../../content/assets/icon-tick__white.svg"
import CrossIcon from "../../../content/assets/icon-cross__white.svg"
import EyeIcon from "../../../content/assets/icon-eye.svg"
import AudienceIcon from "../../../content/assets/icon-audience.svg"
import CloudDataIcon from "../../../content/assets/icon-cloud-data.svg"
import SpeechBubbleIcon from "../../../content/assets/icon-speech-bubble.svg"
import MousePointerIcon from "../../../content/assets/icon-mouse-pointer.svg"
import MemoryIcon from "../../../content/assets/icon-memory.svg"
import LoveHeartIcon from "../../../content/assets/icon-love-heart.svg"
import LoveHeartWhiteIcon from "../../../content/assets/icon-love-heart-white.svg"
import KeyIcon from "../../../content/assets/icon-key.svg"
import DollarSignIcon from "../../../content/assets/icon-dollar-sign.svg"
import FamilyIcon from "../../../content/assets/icon-family-white.svg"
import FemaleIcon from "../../../content/assets/icon-female.svg"
import HandshakeIcon from "../../../content/assets/icon-handshake.svg"

const StyledIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => (props.size === "small" ? "82px" : "112px")};
  height: ${props => (props.size === "small" ? "82px" : "112px")};
  padding: 17px;
  left: -14px;

  .pattern {
    position: absolute;
    width: 30px;
    height: 30px;

    &.light {
      opacity: 0.2;
    }
  }

  .pattern1 {
    top: 0;
    right: 0;
  }

  .pattern2 {
    bottom: 0;
    left: 0;
  }
`

const Box = styled.div`
  position: relative;
  height: 100%;
  padding: 12px;
  background-color: ${props =>
    props.bgColor ? props.bgColor : props.theme.color.teal};
  color: #ffffff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 42px;
    height: 42px;
  }
`

const getIconSrc = icon => {
  let src = null

  if (icon === "location") {
    src = LocationIcon
  } else if (icon === "multi-device") {
    src = MultiDeviceIcon
  } else if (icon === "locked") {
    src = LockedIcon
  } else if (icon === "unlocked") {
    src = UnlockedIcon
  } else if (icon === "cursor") {
    src = CursorIcon
  } else if (icon === "tick") {
    src = TickIcon
  } else if (icon === "cross") {
    src = CrossIcon
  } else if (icon === "audience") {
    src = AudienceIcon
  } else if (icon === "cloud-data") {
    src = CloudDataIcon
  } else if (icon === "dollar-sign") {
    src = DollarSignIcon
  } else if (icon === "key") {
    src = KeyIcon
  } else if (icon === "love-heart") {
    src = LoveHeartIcon
  } else if (icon === "love-heart-white") {
    src = LoveHeartWhiteIcon
  } else if (icon === "memory") {
    src = MemoryIcon
  } else if (icon === "mouse-pointer") {
    src = MousePointerIcon
  } else if (icon === "speech-bubble") {
    src = SpeechBubbleIcon
  } else if (icon === "eye") {
    src = EyeIcon
  } else if (icon === "family") {
    src = FamilyIcon
  } else if (icon === "female") {
    src = FemaleIcon
  } else if (icon === "handshake") {
    src = HandshakeIcon
  }

  return src
}

const StyledIcon = ({ icon, bgColor, size }) => {
  const iconSrc = getIconSrc(icon)

  return (
    <>
      {iconSrc && (
        <StyledIconWrapper size={size}>
          <div className="pattern light pattern1" />
          <div className="pattern light pattern2" />
          <Box bgColor={bgColor}>
            <img src={iconSrc} alt={icon} />
          </Box>
        </StyledIconWrapper>
      )}
    </>
  )
}

export default StyledIcon
