import React from "react"
import styled from "styled-components"
import device from "utils/media"
import StyledIcon from "components/Ui/StyledIcon"

const ItemsList = styled.div`
  margin: 20px 0;
  .item {
    margin-bottom: 8px;
    @media ${device.sm} {
      display: flex;
      flex-direction: row;
    }

    .item_prepend {
      margin-bottom: 5px;
    }

    &.item--small {
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &.item--large {
      .item_prepend {
        @media ${device.sm} {
          margin-right: 20px;
          margin-bottom: 18px;
        }
      }
    }

    .item_number {
      background: ${({ itemBgColour, theme }) =>
        itemBgColour ? itemBgColour : theme.color.light_teal};
      min-width: 78px;
      color: #fff;
      text-align: center;
      display: inline-block;
      padding: 13px 15px;
      font-family: ${props => props.theme.font.feature};
      font-weight: 900;
      font-size: ${props => props.theme.text.xl_2};
      line-height: 1em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ItemHeading = styled.h4`
  font-family: ${props => props.theme.font.feature};
  font-weight: 700;
  text-transform: uppercase;
  font-size: ${props => props.theme.text.base_2};
  margin-bottom: 8px;
  .numbered_items__item__text & {
    margin-top: 8px;
  }
  @media ${device.sm} {
    margin-top: 4px;
    .checkmark_items__item__text & {
      margin-top: 30px;
    }
  }

  @media ${device.xl} {
    font-size: ${props => props.theme.text.md};
  }
`

const ItemDescription = styled.div`
  padding-bottom: 2px;

  @media ${device.sm} {
    font-size: ${props => props.theme.text.sm};
  }

  &.item--large {
    padding-top: ${props => (props.hasHeading ? "0" : "6px")};
    @media ${device.sm} {
      padding-top: ${props => (props.hasHeading ? "0" : "12px")};
    }
    @media ${device.xl} {
      font-size: ${props => props.theme.text.base};
    }
  }

  &.item--small {
    padding-bottom: 2px;
    font-weight: 700;
    font-size: ${props => props.theme.text.xs};
    @media ${device.sm} {
      font-size: ${props => props.theme.text.sm};
    }
    @media ${device.md} {
      font-size: ${props => props.theme.text.sm};
    }

    @media ${device.xxl} {
      font-size: ${props => props.theme.text.base};
    }
  }

  .numbered_items__item__text & {
    padding-bottom: 12px;
  }
`

const StartTextWrapper = styled.div`
  h3 {
    font-weight: 700;
    @media ${device.md} {
      font-size: 24px;
    }
  }
`

const EndTextWrapper = styled.div``

const Checklist = ({
  items,
  listType,
  startText,
  endText,
  itemBgColour,
  itemSize,
}) => {
  return (
    <div>
      {startText && (
        <StartTextWrapper
          dangerouslySetInnerHTML={{
            __html: startText,
          }}
        />
      )}
      <ItemsList itemBgColour={itemBgColour || null}>
        {items.map((item, i) => {
          return (
            <div
              className={`item item--${itemSize || "large"} ${listType}__item`}
              key={i}
            >
              {listType === "numbered_items" && (
                <div className={`item_prepend item--${itemSize || "large"}`}>
                  <div className="item_number">{i + 1}.</div>
                </div>
              )}
              {listType === "checkmark_items" && (
                <div className={`item_prepend item--${itemSize || "large"}`}>
                  {item.icon !== "cross" ? (
                    <StyledIcon icon="tick" size={itemSize || "large"} />
                  ) : (
                    <StyledIcon
                      icon="cross"
                      bgColor="#fb604b"
                      size={itemSize || "large"}
                    />
                  )}
                </div>
              )}

              <div className={`${listType}__item__text`}>
                {item.heading && (
                  <ItemHeading
                    dangerouslySetInnerHTML={{
                      __html: item.heading,
                    }}
                  />
                )}
                {item.description && (
                  <ItemDescription
                    className={`item--${itemSize || "large"}`}
                    hasHeading={item.heading ? true : false}
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                )}
              </div>
            </div>
          )
        })}
      </ItemsList>
      {endText && (
        <EndTextWrapper
          dangerouslySetInnerHTML={{
            __html: endText,
          }}
        />
      )}
    </div>
  )
}

export default Checklist
